import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import Billet from "../"
import Questionnaire from "../../../Components/Questionnaire"
import image_add from "../../../Images/Buttons/add.png"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif";
import image_select from "../../../Images/Buttons/select_circle.png"

const SearchBillet = ({databaseType, databasePath, jsonCompany, jsonLogin, jsonBillReceive, system, updateRecord, setShowFunctions, showMenu, width}) => {
  const [code, setCode] = useState(0)
  const [situation, setSituation] = useState(0)

  const [update, setUpdate] = useState(0)
  const [showChange, setShowChange] = useState(false)  

  const [jsonBillet, setJsonBillet] = useState("")

  const [result, setResult] = useState({})
  const [loading, setLoading] = useState("")
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
    let returnJson = []

    setLoading("Aguarde buscando as informações...")
    setWarning("")
    setError("")

    const jBillet = "{ \"BILL_RECEIVE\": { \"ID\": "+ jsonBillReceive.ID +", \"COMPANY\": { \"ID\": " + jsonBillReceive.COMPANY.ID + " } } }"

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBillet)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILLET/Search", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível pesquisar os Boletos do Cliente. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setResult(returnJson)
      setLoading("")
    })
  }, [databaseType, databasePath, jsonLogin, system, jsonBillReceive, update])

  const add = () => {
    const jBillet = "{ \"BILL_RECEIVE\": "+ JSON.stringify(jsonBillReceive) +" }"

    setJsonBillet(JSON.parse(jBillet))
    setWarning("")
    setError("")
    setShowChange(true)
  }

  const callDelete = (item) => {
    setCode(item.ID)
    setSituation(item.SITUATION)
    setAskQuestionnaire("Deseja realmente excluir o Boleto (" + item.NUMBER + ") ?")
    setShowQuestionnaire(true)
  }

  const cancel = (billet) => {
    let returnJson = []

    setLoading("Aguarde o Cancelamento do Boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", billet)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "BILLET/Cancel", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível Cancelar o Boleto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setError("")
      setWarning("Cancelamento efetuada com sucesso (" + returnJson.OBJECT.NUMBER +")!")
      setShowChange(false)
      setShowFunctions(true)

      const upt = (update + 1)
      setUpdate(upt)

      setLoading("")
    })
  }

  const cancelDelete = () => {
    setShowQuestionnaire(false)
    setCode(0)
  }

  const confirmDelete = () => {
    let returnJson = []

    setLoading("Aguarde excluido o boleto...")
    setWarning("")
    setError("")

    const jBillet = "{ \"ID\": "+ code + ", \"SITUATION\": \""+ situation +"\", \"BILL_RECEIVE\": { \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" } } }"

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jBillet)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILLET/Delete", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível excluir o Boleto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        setShowQuestionnaire(false)
        return
      }

      setError("")
      setWarning("Boleto ("+ returnJson.OBJECT.NUMBER +") excluido com sucesso!");
      setShowChange(false)
      setShowFunctions(true)
      setShowQuestionnaire(false)

      const upt = (update + 1)
      setUpdate(upt)

      setLoading("")
    })
  }

  const extend = (billet) => {
    let returnJson = []

    setLoading("Aguarde prorrogando o boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", billet)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "BILLET/Extend", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível prorrogar o Boleto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setError("")
      setWarning("Prorrogação efetuada com sucesso!")
      setShowChange(false)
      setShowFunctions(true)

      const upt = (update + 1)
      setUpdate(upt)

      setLoading("")
    })
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const issue = (billet) => {
    let returnJson = []

    setLoading("Aguarde emitindo o boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_OLD", JSON.stringify(jsonBillet))
    formData.append("JSON_NEW_1", billet)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "BILLET/Issue", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
    ", \"ERROR\": \"Não foi possível prorrogar o Boleto. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setError("")
      setWarning("")

      alert("Emissão efetuada com sucesso (" + returnJson.OBJECT.NUMBER +")!")
      setShowChange(false)
      setShowFunctions(false)

      const upt = (update + 1)
      setUpdate(upt)

      setLoading("")
    })
  }

  const lower = (json) => {
    let returnJson = []

    setLoading("Aguarde baixando o boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_OLD", JSON.stringify(jsonBillet))
    formData.append("JSON_NEW_1", json)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)   

    axios.put(BASE_URL + "BILLET/Lower", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível baixar o Boleto. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setWarning("")
      setError("Pagamento efetuada com sucesso (" + returnJson.OBJECT.NUMBER +")!")
      setShowChange(false)
      setShowFunctions(false)
      
      updateRecord()
    })
  }

  const previous = () => {
    setWarning("")
    setError("")
    setShowChange(false)
  }

  const protest = (billet) => {
    let returnJson = []

    setLoading("Aguarde protestando o boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", billet)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "BILLET/Protest", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível protestar o Boleto. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setWarning("")
      setError("Protesto efetuado com sucesso!")
      setShowChange(false)
      setShowFunctions(true)

      const count = update + 1
      setUpdate(count)

      setLoading("")
    })
  }

  const resendEmail = (billet) => {
    let returnJson = []

    setLoading("Aguarde re-enviando o boleto...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("JSON_NEW_1", billet)

    axios.post(BASE_URL + "BILLET/SendBill", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível re-enviar o Boleto. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setLoading("")
        return
      }

      setWarning("Re-envio efetuado com sucesso!")
      setError("")
      setLoading("")
    })
  }

  const save = (billet) => {
    let returnJson = []

    if (JSON.parse(billet).ID === undefined)
    {
      setLoading("Aguarde incluindo o Boleto...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", billet)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("GET_ID", true)

      axios.post(BASE_URL + "BILLET/Insert", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível incluir o Boleto. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        setError("")
        setWarning("")

        alert("Inclusão efetuada com sucesso (" + returnJson.OBJECT.NUMBER +")!")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)

        setLoading("")
      })
    }
    else
    {
      setLoading("Aguarde alterando o Boleto...")
      setWarning("")
      setError("")

      const formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_OLD", JSON.stringify(jsonBillet))
      formData.append("JSON_NEW_1", billet)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.put(BASE_URL + "BILLET/Update", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível alterar o Boleto. ("+ response +")\"}") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setLoading("")
          return
        }

        setLoading("")
        setError("")

        alert("Alteração efetuada com sucesso (" + returnJson.OBJECT.NUMBER +")!")
        setShowChange(false)

        const count = update + 1
        setUpdate(count)
      })
    }
  }

  const selected = (billet) => {
    setJsonBillet(billet)
    setWarning("")
    setError("")
    setShowChange(true)
  }

  return(
    <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginBottom: 10}
      : {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginBottom: 10}}>

      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 10,
            marginBottom: 10}}>
            <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
          </div>
        :
        <>
          { warning && 
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
            </div> 
          }

          { error &&
            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 10}}>
              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
            </div>
          }

          { showChange ?
              <Billet
                databaseType={databaseType}
                databasePath={databasePath}
                jsonCompany={jsonCompany}
                jsonLogin={jsonLogin}
                system={system}
                jsonBillet={jsonBillet}
                save={save}
                issue={issue}
                lower={lower}
                cancel={cancel}
                resendEmail={resendEmail}
                extend={extend}
                protest={protest}
                previous={previous}
                showMenu={showMenu}
                width={width} />
            :
              <>
                { showQuestionnaire ?
                    <div style={{marginTop: 10}}>
                      <Questionnaire
                        ask={askQuestionnaire}
                        setConfirm={() => confirmDelete()}
                        setCancel={() => cancelDelete()} />
                    </div>
                  :
                    <>

                      { !result.OBJECT &&
                        <>
                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
                              marginLeft: 5, width: 147}} onClick={() => add()}>
                              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5,
                                paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                <img style={{height: 32, width: 32}} src={image_add} alt="Novo"/>
                              </div>
                              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Novo</label>
                              </div>
                            </div>
                          </div>

                          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                          </div>
                        </>
                      }

                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5}
                        : {paddingLeft: 10, paddingRight: 10}}>
                          
                        { result.OBJECT ?
                            <>
                              <div style={showMenu ?
                                    (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginRight: 10, marginBottom: 4, marginLeft: 5,
                                      minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, marginLeft: 5,
                                      minWidth: 180}
                                :
                                    (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginRight: 10, marginBottom: 4, marginLeft: 5,
                                      minWidth: 180}
                                  : {display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black",
                                      borderBottomWidth: 2, borderBottomStyle: "solid", marginTop: 30, marginBottom: 4, marginLeft: 5,
                                      minWidth: 180}}>

                                <div style={showMenu ?
                                    (width < 1220) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                  :
                                    (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                  <div style={showMenu ?
                                      (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    :  
                                      (width < 700) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                    <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}}>
                                      <label style={{fontWeight: 600}}>Número Título</label>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                                      :
                                        (width < 700) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                      <label>
                                        <label style={{fontWeight: 600}}>Tipo Ambiente</label>
                                      </label>
                                    </div>
                                  </div>

                                  <div style={showMenu ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                    :  
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : (width < 950) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : {display: "flex", flexGrow: 0, marginLeft: 10}}>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 950) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                      <label style={{fontWeight: 600}}>Valor</label>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                      :
                                        (width < 950) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : (width < 800) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>

                                      <label style={{fontWeight: 600}}>Situação</label>
                                    </div>
                                  </div>
                                </div>
                                
                                { showMenu && (width >= 1290) &&
                                  <div style={{display: "flex", flexGrow: 0, marginRight: 8, minWidth: 80, maxWidth: 80}}>
                                    <div style={{marginTop: 2, height: 32, width: 32}} />
                                    <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} />
                                  </div>
                                }

                                { !showMenu && (width >= 1020) &&
                                  <div style={{display: "flex", flexGrow: 0, marginRight: 8, minWidth: 80, maxWidth: 80}}>
                                    <div style={{marginTop: 2, height: 32, width: 32}} />
                                    <div style={{marginTop: 2, marginLeft: 10, height: 28, width: 28}} />
                                  </div>
                                }

                              </div>
                            
                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={showMenu ?
                                      (width < 1290) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", 
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8,
                                      paddingRight: 8, paddingBottom: 8, marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid",
                                      borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingRight: 8, 
                                      paddingBottom: 8, marginTop: 4, minWidth: 180}
                                  : 
                                      (width < 1020) ? {display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", 
                                      borderLeftStyle: "solid", borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, 
                                      paddingRight: 8, paddingBottom: 8, marginTop: 4, minWidth: 180}
                                    : 
                                      {display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", 
                                      borderLeftWidth: 5, borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingRight: 8, 
                                      paddingBottom: 8, marginTop: 4, minWidth: 180}}>

                                  <div style={showMenu ?
                                      (width < 1220) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                    :
                                      (width < 950) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                    <div style={showMenu ?
                                        (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      :  
                                        (width < 700) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                                      <div style={{display: "flex", flexGrow: 1, wordBreak: "break-all", minWidth: 180}}>
                                        <label>{item.NUMBER}</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 970) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                                        :
                                          (width < 700) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>

                                        <label style={ (String(item.ENVIRONMENT) === "1") ? {color: "black"} : {color: "red", fontWeight: 600}} >
                                          { (String(item.ENVIRONMENT) === "1") ? "Produção" : (String(item.ENVIRONMENT) === "2") ? "Homologação" : "" }
                                        </label>
                                      </div>
                                    </div>

                                    <div style={showMenu ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1220) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                      :  
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 950) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10}}>

                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 950) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 120, maxWidth: 120}}>
                                        <label>{formatNumeric(item.ORIGINAL_VALUE,2)}</label>
                                      </div>

                                      <div style={showMenu ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 1220) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}
                                        :
                                          (width < 950) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                          : (width < 800) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 100, maxWidth: 100}}>
                                        <label>
                                          { (String(item.SITUATION) === "1") ? "Lançado" :
                                            (String(item.SITUATION) === "2") ? "Emitido" :
                                              (String(item.SITUATION) === "3") ? "Finalizado" :
                                                (String(item.SITUATION) === "4") ? "Cancelado" : ""
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div style={showMenu?
                                      (width < 1290) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, justifyContent: "center", minWidth: 80, maxWidth: 80}
                                    : 
                                      (width < 1020) ? {display: "flex", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                      : {display: "flex", flexGrow: 0, justifyContent: "center", minWidth: 80, maxWidth: 80}}>

                                    <img
                                      style={{height: 32, width: 32}}
                                      src={image_select}
                                      name="searchBilletSelect"
                                      alt="Selecionar"
                                      onClick={() => selected(item)} />

                                    { String(item.SITUATION) === "1" &&
                                      <img
                                        style={{height: 28, width: 28, marginTop: 2, marginLeft: 10}}
                                        src={image_delete}
                                        name="searchBilletDelete"
                                        alt="Deletar"
                                        onClick={() => callDelete(item)} />
                                    }

                                  </div>
                                </div>
                                ))
                              }
                            </>
                          :
                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                            </div>
                        }

                      </div>
                    </>
                }
              </>
          }
        </>
      }
    </div>
  )
}

export default SearchBillet