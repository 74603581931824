import React, { useState, useEffect } from "react"
import Input from "../../Components/Input"
import InputNumeric from "../../Components/InputNumeric"
import SearchAccount from "../Account/Search"
import Select from "../../Components/Select"
import Titulo from "../../Components/Titulo"
import image_back from "../../Images/Buttons/previous.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"

const BilletConfiguration = ({databaseType, databasePath, jsonBilletConfiguration, jsonCompany, jsonLogin, system, save, back, showMenu, 
  width}) => {

  const [code, setCode] = useState("")
  const [companyCode, setCompanyCode] = useState("")
  const [bankType, setBankType] = useState(1)
  const [description, setDescription] = useState("")
  const [quantityDayProtest, setQuantityDayProtest] = useState("")
  const [quantityDayNegative, setQuantityDayNegative] = useState("")
  const [negativeOrgan, setNegativeOrgan] = useState(0)
  const [acceptsExpired, setAcceptsExpired] = useState(1)
  const [daysLimitReceiving, setDaysLimitReceiving] = useState("")
  const [codeAcceptance, setCodeAcceptance] = useState(1)
  const [titleType, setTitleType] = useState(2)
  const [partialReceipt, setPartialReceipt] = useState(2)
  const [informationAdditional, setInformationAdditional] = useState("")
  const [message, setMessage] = useState("")
  const [discountType, setDiscountType] = useState(0)
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  const [jurosType, setJurosType] = useState(0)
  const [jurosPercentage, setJurosPercentage] = useState(0)
  const [jurosValue, setJurosValue] = useState(0)
  const [penaltyType, setPenaltyType] = useState(0)
  const [penaltyPercentage, setPenaltyPercentage] = useState(0)
  const [penaltyValue, setPenaltyValue] = useState(0)
  const [tariffBillet, setTariffBillet] = useState(0)
  const [tariffPix, setTariffPix] = useState(0)
  const [ruler, setRuler] = useState("")
  const [hybrid, setHybrid] = useState(1)

  const [accountCode, setAccountCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountOwner, setAccountOwner] = useState("")
  const [accountParticipantCode, setAccountParticipantCode] = useState("")

  const [defaul, setDefaul] = useState(2)

  const [showAccount, setShowAccount] = useState(false)

  useEffect(() => {       
    if (jsonBilletConfiguration.ID !== undefined) {
      setCode(jsonBilletConfiguration.ID)
      setCompanyCode(jsonBilletConfiguration.COMPANY.ID)
      setBankType(jsonBilletConfiguration.BANK_TYPE)
      setDescription(jsonBilletConfiguration.DESCRIPTION)
      setQuantityDayProtest(jsonBilletConfiguration.QUANTITY_DAY_PROTEST)
      setQuantityDayNegative(jsonBilletConfiguration.QUANTITY_DAY_NEGATIVE)

      if (((jsonBilletConfiguration.NEGATIVE_ORGAN !== undefined) && (jsonBilletConfiguration.INFORMATION_ADDITIONAL !== null)) && 
        (jsonBilletConfiguration.INFORMATION_ADDITIONAL !== 0)) setNegativeOrgan(jsonBilletConfiguration.NEGATIVE_ORGAN); 
      else setNegativeOrgan(0)

      setAcceptsExpired(jsonBilletConfiguration.ACCEPTS_EXPIRED)
      setDaysLimitReceiving(jsonBilletConfiguration.DAYS_LIMIT_RECEIVING)
      setCodeAcceptance(jsonBilletConfiguration.CODE_ACCEPTANCE)
      setTitleType(parseInt(jsonBilletConfiguration.TITLE_TYPE))
      setPartialReceipt(jsonBilletConfiguration.PARTIAL_RECEIPT)

      if (((jsonBilletConfiguration.INFORMATION_ADDITIONAL !== undefined) && (jsonBilletConfiguration.INFORMATION_ADDITIONAL !== null)) && 
        (jsonBilletConfiguration.INFORMATION_ADDITIONAL !== "")) setInformationAdditional(jsonBilletConfiguration.INFORMATION_ADDITIONAL)

      if (((jsonBilletConfiguration.MESSAGE !== undefined) && (jsonBilletConfiguration.MESSAGE !== null)) && 
        (jsonBilletConfiguration.MESSAGE !== "")) setMessage(jsonBilletConfiguration.MESSAGE); else setMessage("")

      setDiscountType(parseInt(jsonBilletConfiguration.DISCOUNT_TYPE))
      setDiscountPercentage(jsonBilletConfiguration.DISCOUNT_PERCENTAGE)
      setDiscountValue(jsonBilletConfiguration.DISCOUNT_VALUE)
      setJurosType(parseInt(jsonBilletConfiguration.JUROS_TYPE))
      setJurosPercentage(jsonBilletConfiguration.JUROS_PERCENTAGE)
      setJurosValue(jsonBilletConfiguration.JUROS_VALUE)
      setPenaltyType(parseInt(jsonBilletConfiguration.PENALTY_TYPE))
      setPenaltyPercentage(jsonBilletConfiguration.PENALTY_PERCENTAGE)
      setPenaltyValue(jsonBilletConfiguration.PENALTY_VALUE)
      setTariffBillet(jsonBilletConfiguration.TARIFF_BILLET)
      setTariffPix(jsonBilletConfiguration.TARIFF_PIX)

      if (((jsonBilletConfiguration.RULER !== undefined) && (jsonBilletConfiguration.RULER !== null)) 
        && (jsonBilletConfiguration.RULER !== "")) setRuler(jsonBilletConfiguration.RULER); else setRuler("")

      setHybrid(jsonBilletConfiguration.HYBRID)

      setAccountCode(jsonBilletConfiguration.ACCOUNT.ID)
      setAccountNumber(jsonBilletConfiguration.ACCOUNT.NUMBER)
      setAccountOwner(jsonBilletConfiguration.ACCOUNT.OWNER)
      
      if (((jsonBilletConfiguration.ACCOUNT.PARTICIPANT_CODE !== undefined) && (jsonBilletConfiguration.ACCOUNT.PARTICIPANT_CODE !== null)) 
        && (jsonBilletConfiguration.ACCOUNT.PARTICIPANT_CODE !== 0)) setAccountParticipantCode(jsonBilletConfiguration.ACCOUNT.PARTICIPANT_CODE)
      else setAccountParticipantCode("")

      setDefaul(jsonBilletConfiguration.DEFAULT)
    }
    else setCompanyCode(jsonBilletConfiguration.COMPANY.ID)

    document.getElementById("billetConfigurationBankType") && document.getElementById("billetConfigurationBankType").focus()

  }, [jsonBilletConfiguration])

  const cleanAccount = () => {
    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")
    setAccountParticipantCode("")

    setShowAccount(false)
  }

  const enableAccount = () => {
    setShowAccount(!showAccount)
  }

  const fillAccount = (account) => {
    setAccountCode(account.ID)
    setAccountNumber(account.NUMBER)
    setAccountOwner(account.OWNER)

    if ((account.PARTICIPANT_CODE != null) && (account.PARTICIPANT_CODE != 0)) setAccountParticipantCode(account.PARTICIPANT_CODE)

    setShowAccount(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleDaysLimitReceiving = (event) => {
    if (event.target.value !== "") setDaysLimitReceiving(formatNumeric(event.target.value, 0))
  }

  const handleDiscountPercentage = (event) => {
    if (event.target.value !== "") setDiscountPercentage(formatNumeric(event.target.value, 2))
  }

  const handleDiscountValue = (event) => {
    if (event.target.value !== "") setDiscountValue(formatNumeric(event.target.value, 2))
  }  

  const handleJurosPercentage = (event) => {
    if (event.target.value !== "") setJurosPercentage(formatNumeric(event.target.value, 2))
  }
    
  const handleJurosValue = (event) => {
    if (event.target.value !== "") setJurosValue(formatNumeric(event.target.value, 2))
  }

  const handlePenaltyPercentage = (event) => {
    if (event.target.value !== "") setPenaltyPercentage(formatNumeric(event.target.value, 2))
  }

  const handlePenaltyValue = (event) => {
    if (event.target.value !== "") setPenaltyValue(formatNumeric(event.target.value, 2))        
  }

  const handleQuantityDayNegative = (event) => {
    if (event.target.value !== "") setQuantityDayNegative(formatNumeric(event.target.value, 0))
  }

  const handleQuantityDayProtest = (event) => {
    if (event.target.value !== "") setQuantityDayProtest(formatNumeric(event.target.value, 0))
  }

  const handleTariffBillet = (event) => {
    if (event.target.value !== "") setTariffBillet(formatNumeric(event.target.value, 2))        
  }

  const handleTariffPix = (event) => {
    if (event.target.value !== "") setTariffPix(formatNumeric(event.target.value, 2))        
  }

  const callSave = () => {
    let json = "{"
    if (((code !== undefined) && (code !== "")) && (code !== 0)) json += " \"ID\": "+ code + ","
    json += " \"COMPANY\": { \"ID\": " + companyCode + " }"

    if (String(bankType) === "1") json += ", \"BANK_TYPE\": 1"
    else if (String(bankType) === "2") json += ", \"BANK_TYPE\": 2"

    if (description !== undefined) json += ", \"DESCRIPTION\": \""+ description +"\""
    else json += ", \"DESCRIPTION\": \"\""

    if (String(bankType) === "1"){
      if ((quantityDayProtest !== undefined) && (quantityDayProtest !== "")) json += ", \"QUANTITY_DAY_PROTEST\": "+ quantityDayProtest
      else json += ", \"QUANTITY_DAY_PROTEST\": 0"

      if ((quantityDayNegative !== undefined) && (quantityDayNegative !== "")) json += ", \"QUANTITY_DAY_NEGATIVE\": "+ quantityDayNegative
      else json += ", \"QUANTITY_DAY_NEGATIVE\": 0"

      if (negativeOrgan !== undefined) json += ", \"NEGATIVE_ORGAN\": \""+ negativeOrgan + "\""
      else json += ", \"NEGATIVE_ORGAN\": 0"      

      if (acceptsExpired !== undefined) {
        if (String(acceptsExpired) === "1") json += ", \"ACCEPTS_EXPIRED\": 1"
        else json += ", \"ACCEPTS_EXPIRED\": 2"
      } else json += ", \"ACCEPTS_EXPIRED\": 2"

      if (codeAcceptance !== undefined) {
        if (String(codeAcceptance) === "1") json += ", \"CODE_ACCEPTANCE\": 1"
        else json += ", \"CODE_ACCEPTANCE\": 2"
      } else json += ", \"CODE_ACCEPTANCE\": 1"      

      if (titleType !== undefined) json += ", \"TITLE_TYPE\": "+ titleType
      else json += ", \"TITLE_TYPE\": 2"

      if (partialReceipt !== undefined) {
        if (String(partialReceipt) === "1") json += ", \"PARTIAL_RECEIPT\": 1"
        else json += ", \"PARTIAL_RECEIPT\": 2"
      } else json += ", \"PARTIAL_RECEIPT\": 2"      

      if (ruler !== undefined) json += ", \"RULER\": \""+ ruler + "\""
      else json += ", \"RULER\": \"\""      
    }
    else if (String(bankType) === "2"){
      json += ", \"QUANTITY_DAY_PROTEST\": 0"
      json += ", \"QUANTITY_DAY_NEGATIVE\": 0"
      json += ", \"NEGATIVE_ORGAN\": 0"
      json += ", \"ACCEPTS_EXPIRED\": 2"
      json += ", \"CODE_ACCEPTANCE\": 1"
      json += ", \"TITLE_TYPE\": 2"
      json += ", \"RULER\": \"\""
    }

    if ((daysLimitReceiving !== undefined) && (daysLimitReceiving !== "")) json += ", \"DAYS_LIMIT_RECEIVING\": "+ daysLimitReceiving
    else json += ", \"DAYS_LIMIT_RECEIVING\": 0"
        
    if (informationAdditional !== undefined) json += ", \"INFORMATION_ADDITIONAL\": \""+ informationAdditional + "\""
    else json += ", \"INFORMATION_ADDITIONAL\": \"\""

    if (message !== undefined) json += ", \"MESSAGE\": \""+ message + "\""
    else json += ", \"MESSAGE\": \"\""

    json += ", \"DISCOUNT_TYPE\": \""+ discountType + "\""
    if (discountPercentage !== undefined) json += ", \"DISCOUNT_PERCENTAGE\": "+ discountPercentage
    if (discountValue !== undefined) json += ", \"DISCOUNT_VALUE\": "+ discountValue

    json += ", \"JUROS_TYPE\": \""+ jurosType + "\""
    if (jurosPercentage !== undefined) json += ", \"JUROS_PERCENTAGE\": "+ jurosPercentage
    if (jurosValue !== undefined) json += ", \"JUROS_VALUE\": "+ jurosValue

    json += ", \"PENALTY_TYPE\": \""+ penaltyType + "\""
    if (penaltyPercentage !== undefined) json += ", \"PENALTY_PERCENTAGE\": "+ penaltyPercentage
    if (penaltyValue !== undefined) json += ", \"PENALTY_VALUE\": "+ penaltyValue

    if (tariffBillet !== undefined) json += ", \"TARIFF_BILLET\": "+ tariffBillet
    if (tariffPix !== undefined) json += ", \"TARIFF_PIX\": "+ tariffPix

    if (hybrid !== undefined) {
      if (String(hybrid) === "1") json += ", \"HYBRID\": 1"
      else json += ", \"HYBRID\": 2"
    } else json += ", \"HYBRID\": 2"

    if (accountCode !== "")
    {
      json += ", \"ACCOUNT\": { \"ID\": " + accountCode + 
      ", \"NUMBER\": \"" + accountNumber + "\"" +
      ", \"OWNER\": \"" + accountOwner + "\""      
      if ((accountParticipantCode !== undefined) && (accountParticipantCode !== "")) json += ", \"PARTICIPANT_CODE\": " + accountParticipantCode
      json += " }"
    }

    if (defaul !== undefined) {
      if (String(defaul) === "1") json += ", \"DEFAULT\": 1"
      else json += ", \"DEFAULT\": 2"
    } else json += ", \"DEFAULT\": 2"

    json += " }"
    save(json)
  }

  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>

      { !showAccount &&
        <div style={{display: "flex", flexDirection: "column", flexGrow: 0}}>
          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={callSave}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 5,
              marginLeft: 5, width: 147}} onClick={() => back()}>
              <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,
                paddingBottom: 5, paddingLeft: 10}}>
                <img style={{height: 32, width: 32}} src={image_back} alt="Retornar"/>
              </div>
              <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
              </div>
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
          </div>
        
          <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0, marginTop: 10}
            : {paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

            <div style={showMenu ?
                (width < 1200) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
              :
                (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
              <div style={showMenu ?
                  (width < 1200) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 0, minWidth: 240, maxWidth: 240}
                :
                  (width < 930) ? {display: "flex", flexGrow: 1, minWidth: 180}
                  : {display: "flex", flexGrow: 0, minWidth: 240, maxWidth: 240}}>
                <Select
                  name="billetConfigurationBankType"
                  description="Tipo"
                  value={bankType}
                  require={false}
                  options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Mentes que Pensam\" }"+
                    ", {\"ID\": 2, \"VALUE\": \"Banco do Brasil\" }] }")}
                  image={false}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  set={setBankType} />
              </div>

              <div style={showMenu ?
                  (width < 1200) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                :
                  (width < 930) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                  : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                <Input
                  type="text"
                  name="billetConfigurationDescription"
                  description="Descrição"
                  value={description}
                  maxLength={120}
                  disabled={false}
                  require={true}
                  image={false}
                  typeInput={2}
                  onSelect={setFieldSelected}
                  onChange={(event) => setDescription(event.target.value)} />
              </div>
            </div>

            { parseInt(bankType) == 1 ?
                <>
                  <div style={showMenu ?
                      (width < 940) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                        : (width < 940) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                        : (width < 680) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="billetConfigurationQuantityDayProtest"
                          description="Dias Protesto"
                          value={quantityDayProtest || ""}
                          maxLength={3}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleQuantityDayProtest} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                          : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="billetConfigurationQuantityDayNegative"
                          description="Dias Negativação"
                          value={quantityDayNegative || ""}
                          maxLength={3}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleQuantityDayNegative} />
                      </div>
                    </div>
                    <div style={showMenu ?
                        (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                      <Select
                        name="billetConfigurationNegativeOrgan"
                        description="Orgão negativador"
                        value={negativeOrgan || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 10, \"VALUE\": \"SERASA\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setNegativeOrgan} />
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 940) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    : 
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                        : (width < 940) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 180, maxWidth: 180}}>
                        <Select
                          name="billetConfigurationAcceptsExpired"
                          description="Aceita vencido"
                          value={acceptsExpired}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setAcceptsExpired} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 940) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="billetConfigurationDaysLimitReceiving"
                          description="Dias limite receb."
                          value={daysLimitReceiving || ""}
                          maxLength={3}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleDaysLimitReceiving} />
                      </div>
                    </div>
                    <div style={showMenu ?
                        (width < 940) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 680) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                      <Select
                        name="billetConfigurationCodeAcceptance"
                        description="Reconhece dívida"
                        value={codeAcceptance}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setCodeAcceptance} />
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 560) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                      : (width < 840) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 560) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetConfigurationTitleType"
                        description="Tipo"
                        value={titleType}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Cheque\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Dup. Mercantil\" }, {\"ID\": 3, \"VALUE\": \"Dup. Mtil\" }"+
                          ", {\"ID\": 4, \"VALUE\": \"Dup. Serviço\" }, {\"ID\": 5, \"VALUE\": \"Dup. Serviço Indicação\" }"+
                          ", {\"ID\": 6, \"VALUE\": \"Dup. Rural\" }, {\"ID\": 7, \"VALUE\": \"Câmbio\" }"+
                          ", {\"ID\": 8, \"VALUE\": \"Nt. Créd. Comercial\" }, {\"ID\": 9, \"VALUE\": \"Nt. Créd. Exportação\" }"+
                          ", {\"ID\": 10, \"VALUE\": \"Nt. Créd. Industrial\" }, {\"ID\": 11, \"VALUE\": \"Nt. Créd. Rural\" }"+
                          ", {\"ID\": 12, \"VALUE\": \"Nt. Promis.\" }, {\"ID\": 13, \"VALUE\": \"Nt. Promis. Rural\" }"+
                          ", {\"ID\": 14, \"VALUE\": \"Triplicata Mercantil\" }, {\"ID\": 15, \"VALUE\": \"Triplicata Serviço\" }"+
                          ", {\"ID\": 16, \"VALUE\": \"Nota Seguro\" }, {\"ID\": 17, \"VALUE\": \"Recibo\" }, {\"ID\": 18, \"VALUE\": \"Fatura\" }"+
                          ", {\"ID\": 19, \"VALUE\": \"Nota Débito\" }, {\"ID\": 20, \"VALUE\": \"Apólice Seguro\" }"+
                          ", {\"ID\": 21, \"VALUE\": \"Mensalidade Escolar\" }, {\"ID\": 22, \"VALUE\": \"Parcela Consórcio\" }"+
                          ", {\"ID\": 23, \"VALUE\": \"Dívida Ativa União\" }, {\"ID\": 24, \"VALUE\": \"Dívida Ativa Estado\" }"+
                          ", {\"ID\": 25, \"VALUE\": \"Dívida Ativa Município\" }, {\"ID\": 31, \"VALUE\": \"Cartão Crédito\" }"+
                          ", {\"ID\": 32, \"VALUE\": \"Boleto Proposta\" }, {\"ID\": 33, \"VALUE\": \"Boleto Aporte\" }"+
                          ", {\"ID\": 99, \"VALUE\": \"Outros\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setTitleType} />
                    </div>
                    <div style={showMenu ?
                        (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        : (width < 840) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                      :            
                        (width < 560) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                      <Select
                        name="billetConfigurationPartialReceipt"
                        description="Recebimento parcial"
                        value={partialReceipt}
                        require={true}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setPartialReceipt} />
                    </div>
                  </div>        

                  <div style={showMenu ?
                      (width < 1080) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 780) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                    <div style={showMenu ?
                        (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <Input
                        type="text"
                        name="billetConfigurationInformationAdditional"
                        description="Informações Ad."
                        value={informationAdditional}
                        maxLength={15}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setInformationAdditional(event.target.value)} />
                    </div>
                    <div style={showMenu ?
                        (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                      <Input
                        type="text"
                        name="billetConfigurationMessage"
                        description="Mensagem"
                        value={message}
                        maxLength={30}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setMessage(event.target.value)} />
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 960) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetConfigurationDiscountType"
                        description="Tipo desconto"
                        value={discountType || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Sem\" }, {\"ID\": 1, \"VALUE\": \"Valor\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Percentual\" }, {\"ID\": 3, \"VALUE\": \"Antecipação\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setDiscountType} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 680) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationDiscountPercentage"
                          description="% desconto"
                          value={discountPercentage || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleDiscountPercentage} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationDiscountValue"
                          description="Valor desconto"
                          value={discountValue || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleDiscountValue} />
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 960) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetConfigurationJurosType"
                        description="Tipo juros"
                        value={jurosType || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 1, \"VALUE\": \"Dia\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Mensal\" }, {\"ID\": 3, \"VALUE\": \"Isento\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setJurosType} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 680) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationJurosPercentage"
                          description="% juros"
                          value={jurosPercentage || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleJurosPercentage} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationJurosValue"
                          description="Valor juros"
                          value={jurosValue || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleJurosValue} />
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 960) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetConfigurationPenaltyType"
                        description="Tipo multa"
                        value={penaltyType || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 1, \"VALUE\": \"Dia\" }"+
                          ", {\"ID\": 2, \"VALUE\": \"Mensal\" }, {\"ID\": 3, \"VALUE\": \"Isento\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setPenaltyType} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 680) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationPenaltyPercentage"
                          description="% multa"
                          value={penaltyPercentage || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handlePenaltyPercentage} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationPenaltyValue"
                          description="Valor multa"
                          value={penaltyValue || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handlePenaltyValue} />
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 490) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                      : (width < 760) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 490) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetConfigurationTariffBillet"
                        description="Tarifa Boleto"
                        value={tariffBillet || ""}
                        maxLength={13}
                        disabled={false}
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={handleTariffBillet} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetConfigurationTariffPix"
                        description="Tarifa PIX"
                        value={tariffPix || ""}
                        maxLength={13}
                        disabled={false}
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={handleTariffPix} />
                    </div>
                  </div>

                  { showMenu && (width >= 950) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                        <Input
                          type="text"
                          name="billetConfigurationRuler"
                          description="Régua"
                          value={ruler}
                          maxLength={120}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setRuler(event.target.value)} /> 
                      </div>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationHybrid"
                          description="Híbrido"
                          value={hybrid}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setHybrid} />
                      </div>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  { showMenu && (width < 950) &&              
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                      <div style={
                        (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                        : (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="billetConfigurationRuler"
                            description="Régua"
                            value={ruler}
                            maxLength={120}
                            disabled={false}
                            require={false}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setRuler(event.target.value)} /> 
                        </div>
                        <div style={
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                          <Select
                            name="billetConfigurationHybrid"
                            description="Híbrido"
                            value={hybrid}
                            require={true}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={setHybrid} />
                        </div>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  { !showMenu && (width >= 670) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                        <Input
                          type="text"
                          name="billetConfigurationRuler"
                          description="Régua"
                          value={ruler}
                          maxLength={120}
                          disabled={false}
                          require={false}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={(event) => setRuler(event.target.value)} /> 
                      </div>

                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationHybrid"
                          description="Híbrido"
                          value={hybrid}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setHybrid} />
                      </div>

                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  { !showMenu && (width < 670) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                      <div style={
                        (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                          <Input
                            type="text"
                            name="billetConfigurationRuler"
                            description="Régua"
                            value={ruler}
                            maxLength={120}
                            disabled={false}
                            require={false}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={(event) => setRuler(event.target.value)} /> 
                        </div>
                        <div style={
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                          <Select
                            name="billetConfigurationHybrid"
                            description="Híbrido"
                            value={hybrid}
                            require={true}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={setHybrid} />
                        </div>
                      </div>
                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  <div style={{marginTop: 10}}>       
                    <Titulo description="Conta" />
                  </div>                            

                  <div style={showMenu ?
                      (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                    :
                      (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                    <div style={showMenu ?                                  
                        (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                      :
                        (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                      <div style={showMenu ?
                          (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                        :
                          (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                        <Input
                          type="text"
                          name="BillerConfigurationAccountNumber"
                          description="Número"
                          value={accountNumber}
                          maxLength={10}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected} />
                      </div>
                      <div style={showMenu ?
                          (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                          : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                        :
                          (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                          : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                        <Input
                          type="text"
                          name="BillerConfigurationAccountOwner"
                          description="Titular"
                          value={accountOwner}
                          maxLength={50}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected} />
                      </div>
                    </div>

                    <div style={(showMenu) ? 
                        (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccount}>
                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccount}>
                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                      </div>
                    </div>
                  </div>
                </>
              :
                <>
                  <div style={showMenu ?
                      (width < 960) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetConfigurationDiscountType"
                        description="Tipo desconto"
                        value={discountType || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Sem\" }, {\"ID\": 3, \"VALUE\": \"Antecipação\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setDiscountType} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 680) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationDiscountPercentage"
                          description="% desconto"
                          value={discountPercentage || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleDiscountPercentage} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationDiscountValue"
                          description="Valor desconto"
                          value={discountValue || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleDiscountValue} />
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 960) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetConfigurationJurosType"
                        description="Tipo juros"
                        value={jurosType || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 2, \"VALUE\": \"Mensal\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setJurosType} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 680) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationJurosPercentage"
                          description="% juros"
                          value={jurosPercentage || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleJurosPercentage} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationJurosValue"
                          description="Valor juros"
                          value={jurosValue || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleJurosValue} />
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 960) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 680) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>

                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                      <Select
                        name="billetConfigurationPenaltyType"
                        description="Tipo multa"
                        value={penaltyType || 0}
                        require={false}
                        options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Dispensar\" }, {\"ID\": 2, \"VALUE\": \"Mensal\" }] }")}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        set={setPenaltyType} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : (width < 760) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 960) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                        : (width < 680) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                        : {display: "flex", flexGrow: 0, flexDirection: "row"}}>

                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationPenaltyPercentage"
                          description="% multa"
                          value={penaltyPercentage || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handlePenaltyPercentage} />
                      </div>
                      <div style={showMenu ?
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 960) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                        :
                          (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                          : (width < 680) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>

                        <InputNumeric
                          type="text"
                          name="billetConfigurationPenaltyValue"
                          description="Valor multa"
                          value={penaltyValue || ""}
                          maxLength={13}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handlePenaltyValue} />
                      </div>
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 1080) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 780) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                    <div style={showMenu ?
                        (width < 1080) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 780) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <Input
                        type="text"
                        name="billetConfigurationInformationAdditional"
                        description="Informações Ad."
                        value={informationAdditional}
                        maxLength={15}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setInformationAdditional(event.target.value)} />
                    </div>
                    <div style={showMenu ?
                        (width < 1080) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 780) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>

                      <Input
                        type="text"
                        name="billetConfigurationMessage"
                        description="Mensagem"
                        value={message}
                        maxLength={30}
                        disabled={false}
                        require={false}
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={(event) => setMessage(event.target.value)} />
                    </div>
                  </div>

                  <div style={showMenu ?
                      (width < 490) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : (width < 690) ? {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                      : (width < 760) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}
                    :
                      (width < 490) ? {display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}
                      : {display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>                
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetConfigurationTariffBillet"
                        description="Tarifa Boleto"
                        value={tariffBillet || ""}
                        maxLength={13}
                        disabled={false}
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={handleTariffBillet} />
                    </div>
                    <div style={showMenu ?
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                        : (width < 760) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                      :
                        (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                        : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                      <InputNumeric
                        type="text"
                        name="billetConfigurationTariffPix"
                        description="Tarifa PIX"
                        value={tariffPix || ""}
                        maxLength={13}
                        disabled={false}
                        require={true}
                        negative={false} 
                        image={false}
                        typeInput={2}
                        onSelect={setFieldSelected}
                        onChange={handleTariffPix} />
                    </div>
                  </div>

                  { showMenu && (width >= 940) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="billetConfigurationDaysLimitReceiving"
                          description="Dias limite receb."
                          value={daysLimitReceiving || ""}
                          maxLength={3}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleDaysLimitReceiving} />
                      </div>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationHybrid"
                          description="Híbrido"
                          value={hybrid}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setHybrid} />
                      </div>                
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  { showMenu && (width < 940) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                      <div style={
                          (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                          : (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={
                            (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}                      
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}
                            : (width < 740) ?{display: "flex", flexGrow: 1, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                          <InputNumeric
                            type="text"
                            name="billetConfigurationDaysLimitReceiving"
                            description="Dias limite receb."
                            value={daysLimitReceiving || ""}
                            maxLength={3}
                            disabled={false}
                            require={false}
                            negative={false} 
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleDaysLimitReceiving} />
                        </div>
                        <div style={
                            (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}                      
                            : (width < 690) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}
                            : (width < 740) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                          <Select
                            name="billetConfigurationHybrid"
                            description="Híbrido"
                            value={hybrid}
                            require={true}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={setHybrid} />
                        </div>                
                      </div>
                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  { !showMenu && (width >= 710) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "row", marginTop: 10}}>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                        <InputNumeric
                          type="text"
                          name="billetConfigurationDaysLimitReceiving"
                          description="Dias limite receb."
                          value={daysLimitReceiving || ""}
                          maxLength={3}
                          disabled={false}
                          require={false}
                          negative={false} 
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          onChange={handleDaysLimitReceiving} />
                      </div>
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationHybrid"
                          description="Híbrido"
                          value={hybrid}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setHybrid} />
                      </div>                
                      <div style={{display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  { !showMenu && (width < 710) &&
                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                      <div style={
                          (width < 490) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>
                        <div style={
                            (width < 490) ? {display: "flex", flexGrow: 1, minWidth: 180}                      
                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 180}}>
                          <InputNumeric
                            type="text"
                            name="billetConfigurationDaysLimitReceiving"
                            description="Dias limite receb."
                            value={daysLimitReceiving || ""}
                            maxLength={3}
                            disabled={false}
                            require={false}
                            negative={false} 
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            onChange={handleDaysLimitReceiving} />
                        </div>
                        <div style={
                            (width < 490) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}                      
                            : {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 180}}>
                          <Select
                            name="billetConfigurationHybrid"
                            description="Híbrido"
                            value={hybrid}
                            require={true}
                            options={JSON.parse("{ \"RESULT\": [{\"ID\": 1, \"VALUE\": \"Sim\" }, {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                            image={false}
                            typeInput={2}
                            onSelect={setFieldSelected}
                            set={setHybrid} />
                        </div>                
                      </div>
                      <div style={{display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}}>
                        <Select
                          name="billetConfigurationDefault"
                          description="Padrão"
                          value={defaul}
                          require={true}
                          options={JSON.parse("{ \"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\" }, {\"ID\": 1, \"VALUE\": \"Sim\" }"+
                            ", {\"ID\": 2, \"VALUE\": \"Não\" }] }")}
                          image={false}
                          typeInput={2}
                          onSelect={setFieldSelected}
                          set={setDefaul} />
                      </div>
                    </div>
                  }

                  <div style={{marginTop: 10}}>       
                    <Titulo description="Conta" />
                  </div>                            

                  <div style={showMenu ?
                      (width < 1090) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                    :
                      (width < 820) ? {display: "flex", flexDirection: "column", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}
                      : {display: "flex", flexDirection: "row", flexGrow: 0, paddingLeft: 10, paddingRight: 10, marginTop: 10}}>

                    <div style={showMenu ?                                  
                        (width < 990) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                      :
                        (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                      <div style={showMenu ?
                          (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                        :
                          (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                          : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                        <Input
                          type="text"
                          name="BillerConfigurationAccountNumber"
                          description="Número"
                          value={accountNumber}
                          maxLength={10}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected} />
                      </div>
                      <div style={showMenu ?
                          (width < 990) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                          : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}
                        :
                          (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180, marginTop: 10}
                          : {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 10}}>
                        <Input
                          type="text"
                          name="BillerConfigurationAccountOwner"
                          description="Titular"
                          value={accountOwner}
                          maxLength={50}
                          disabled={true}
                          require={true}
                          image={false}
                          typeInput={2} 
                          onSelect={setFieldSelected} />
                      </div>
                    </div>

                    <div style={(showMenu) ? 
                        (width < 1090) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                      : 
                        (width < 820) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={enableAccount}>
                        <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                      </div>
                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccount}>
                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                      </div>
                    </div>
                  </div>
                </>
            }

          </div>
        </div>
      }

      { showAccount &&
        <div style={{marginTop : 10}}>
          <div style={{marginLeft: 10}}>
            <label style={{fontWeight: 600}} >Selecione a Conta:</label>
          </div>

          <SearchAccount
            modoSearch={true}
            databaseType={databaseType}
            databasePath={databasePath}
            jsonCompany={jsonCompany}                            
            jsonLogin={jsonLogin}
            system={system}
            setAdd={() => alert("")}
            setSelected={fillAccount} 
            setReturn={() => setShowAccount(false)} 
            showMenu={showMenu}
            width={width} />
        </div>
      }

    </div>
  )
}

export default BilletConfiguration